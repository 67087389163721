<template>
	<nav class="default-navbar row" align-y="center" align-x="between">
		<div class="row" align-y="center" gutter="30">
			<router-link class="logo" to="/">
				<img src="@/assets/logo.png" width="100%">
			</router-link>
			<follow-us v-if="$root.rwd < 2" />
		</div>

		<!-- 電腦版 -->
		<div v-if="$root.rwd < 2" class="row" gutter="20">
			<template v-for="(item, i) in list">
				<router-link :to="item.to" class="col" align-x="center" gutter="10" :key="i">
					<img :src="item.img" height="30">
					<div style="width:100%;">
						<div class="active-line" />
					</div>
					<small><b>{{item.label}}</b></small>
				</router-link>
			</template>
			<el-dropdown v-if="_auth">
				<router-link to="/profile" class="col" align-x="center" gutter="10">
					<img src="@/assets/navbar/profile.png" height="30">
					<div style="width:100%;">
						<div class="active-line" />
					</div>
					<small><b>會員資料</b></small>
				</router-link>
				<el-dropdown-menu slot="dropdown">
					<router-link to="/profile">
						<el-dropdown-item>個人資料</el-dropdown-item>
					</router-link>
					<template v-if="!is_manager || is_member">
						<router-link to="/cart">
							<el-dropdown-item divided>購物車</el-dropdown-item>
						</router-link>
						<router-link to="/order">
							<el-dropdown-item>查看訂單</el-dropdown-item>
						</router-link>
					</template>
					<router-link to="/manager" v-if="is_manager">
						<el-dropdown-item divided>後台管理</el-dropdown-item>
					</router-link>
					<div @click="handleLogout()">
						<el-dropdown-item divided>登出</el-dropdown-item>
					</div>
				</el-dropdown-menu>
			</el-dropdown>
			<template v-else>
				<router-link to="/login" class="col" align-x="center" gutter="10">
					<img src="@/assets/navbar/profile.png" height="30">
					<div style="width:100%;">
						<div class="active-line" />
					</div>
					<small><b>會員登入</b></small>
				</router-link>
			</template>
		</div>

		<!-- 手機版 -->
		<template v-else>
			<img src="@/assets/menu.png" width="60" @click="drawerVisible = true">
			<el-drawer class="navber-mobile" :visible.sync="drawerVisible" append-to-body size="100%">
				<router-link slot="title" to="/" @click.native="drawerVisible = false">
					<img src="@/assets/logo-white.png" width="150">
				</router-link>
				<div class="col" gutter="10" align-x="left">
					<template v-for="(item, i) in list">
						<router-link :to="item.to" :key="i" @click.native="drawerVisible = false">{{item.label}}</router-link>
					</template>
					<div align-self="stretch">
						<el-divider />
					</div>
					<router-link :to="_auth ? '/profile' : '/login'" @click.native="drawerVisible = false">{{_auth ? "個人資料" : "會員登入"}}</router-link>
					<template v-if="_auth">
						<router-link v-if="is_manager" to="/manager">後台管理</router-link>
						<template v-if="!is_manager || is_member">
							<router-link to="/cart" @click.native="drawerVisible = false">購物車</router-link>
							<router-link to="/order" @click.native="drawerVisible = false">查看訂單</router-link>
						</template>
						<el-button type="text" @click="handleLogout()">登出</el-button>
					</template>
					<div align-self="stretch">
						<el-divider />
					</div>
					<follow-us white />
				</div>
				<br>
				<br>
				<br>
				<br>
				<br>
				<br>
				<br>
				<br>
				<br>
				<br>
			</el-drawer>
		</template>
	</nav>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		FollowUs: () => import("@/components/follow-us")
	},
	data() {
		return {
			drawerVisible: false,
			list: [
				{
					img: require("@/assets/navbar/about.png"),
					to: "/about",
					label: "關於金枝"
				},
				{
					img: require("@/assets/navbar/activity.png"),
					to: "/activity",
					label: "最新活動"
				},
				{
					img: require("@/assets/navbar/news.png"),
					to: "/news",
					label: "焦點消息"
				},
				{
					img: require("@/assets/navbar/work.png"),
					to: "/portfolio",
					label: "系列作品"
				},
				{
					img: require("@/assets/navbar/perform.png"),
					to: "/perform",
					label: "金枝走演"
				},
				{
					img: require("@/assets/navbar/join.png"),
					to: "/join",
					label: "加入金枝"
				},
				{
					img: require("@/assets/navbar/product.png"),
					to: "/product",
					label: "金枝百貨舖"
				}
			]
		}
	},
	computed: {
		...mapState("auth", ["_auth"]),
		is_manager() {
			const { roles = new Array } = this._auth || new Object;
			return ["manager", "admin"].some(role => roles.includes(role));
		},
		is_member() {
			const { roles = new Array } = this._auth || new Object;
			return ["member", "donor"].some(role => roles.includes(role));
		}
	},
	methods: {
		...mapActions("auth", ["_logout"]),
		async handleLogout() {
			if (this.drawerVisible) this.drawerVisible = false;
			this.$message.success(await this._logout());
			const { matched } = this.$route;
			if (matched.some(({ meta }) => meta.permission)) this.$router.replace("/login");
		}
	}
}
</script>

<style lang="scss" scoped>
.default-navbar {
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
	white-space: nowrap;

	@media screen and (min-width: 769px) {
		padding: 1em;
	}

	a {
		line-height: 1;
	}

	.logo {
		width: 10em;
		margin-left: 1.25em;
	}

	.active-line {
		height: 2px;
		width: 100%;
		background: transparent;
		transition: all 0.5s;
	}

	.router-link-active {
		.active-line {
			transition-delay: 0.3s;
			background: $theme;
		}
	}
	.el-dropdown {
		font-size: inherit;
	}
}
</style>

<style lang="scss">
.navber-mobile * {
	font-size: 1.5rem;
}
.el-drawer {
	background: $theme-light;
	* {
		color: #fff;
	}
	.router-link-active {
		text-decoration: underline;
	}
}
.el-drawer__body {
	height: 0;
	overflow: auto;
	padding: 0 20px;
	background-size: 265px;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-image: url("../../assets/background/straw1.png");
}
</style>